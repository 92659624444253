/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/

/**
 * Table Of Content
 *
 *  1. Color system
 *  2. Options
 *  3. Body
 *  4. Typography
 *  5. Breadcrumbs
 *  6. Cards
 *  7. Dropdowns
 *  8. Buttons
 *  9. Typography
 *  10. Progress bars
 *  11. Tables
 *  12. Forms
 *  14. Component
 */
@import "all/custom/variables.scss";

// Import Bootstrap source files (Required file)
@import "all/bootstrap/bootstrap.scss";
@import "all/custom/custom.scss";

//This is for the icons (Required file)
@import "all/icons/font-awesome/css/fontawesome-all.css";
@import "all/icons/simple-line-icons/css/simple-line-icons.css";
@import "all/icons/weather-icons/css/weather-icons.css";
@import "all/icons/themify-icons/themify-icons.css";
@import "all/icons/flag-icon-css/flag-icon.min.css";
@import "all/icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import "all/icons/crypto-icons/cryptocoins.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body, html, #root {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
}

.pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.custom-invalid-feedback {
  display: block;
}


.table th {
  padding: 0.5rem 1rem;
  font-size: 13px;
}

.table td {
  padding: 0.2rem 1rem;
  font-size: 13px;
}

.input-group.is-invalid ~ .invalid-feedback {
  display: block;
}

.clickable-row {
  cursor: pointer;
  -webkit-transition: background-color .3s ease-in-out;
  -moz-transition: background-color .3s ease-in-out;
  -o-transition: background-color .3s ease-in-out;
  -ms-transition: background-color .3s ease-in-out;
  transition: background-color .3s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.15) !important;
  }
}

.custom-switch .custom-control-label::after {
  top: calc(0.15625rem + 2px);
}

._9mf2w {
  display: none !important;
}

.text-green {
  color: #86cc1a;
}

.react-datepicker-wrapper {
  width: 100%
}
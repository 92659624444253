// stylelint-disable declaration-no-important

// Margin and Padding for rtl version

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}r#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
          #{$prop}-right: 0 !important;
        }

        .#{$abbrev}l#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
          #{$prop}-left: 0 !important;
        }
      }
    }
  }
}
